<template>
    <modal
        transition="nice-modal-fade"
        class="new-modal-default"
        :name="modal_name"
        :delay="100"
        height="auto"
        width="350"
        :adaptive="true"
        :scrollable="false"
    >
        <div class="v-modal-content p-4" style="background-color: #dfe8f4 !important;">
            <div class="d-flex justify-content-center">
                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/parrot.svg" class="p-3" style="height: 6rem !important" alt="">
            </div>
            <div class="py-2 text-center">
                <h3 class="text-center fs-20 fw-normal">Submitted!</h3>
                <span class="fs-13 fw-normal py-2 text-center">
                    {{message}}
                </span>
            </div>
            <div v-if="is_share" class="footer mt-3 d-flex justify-content-center">
                <button class="btn close-btn px-5 text-white fs-14" @click="hideSubmitSuccessfulModal()">Close</button>
                <button class="btn btn-secondary px-5 text-white fs-14 ml-3" @click="showSharePopup()">Share link</button>
            </div>
            <div v-else class="footer mt-3 d-flex justify-content-center">
                <button class="btn close-btn px-5 text-white fs-14" @click="hideSubmitSuccessfulModal()">Close</button>
            </div>
        </div>
    </modal>
</template>

<script>

export default({
    props:['modal_name', 'message', 'is_share'],
    methods: {
        hideSubmitSuccessfulModal(){
            this.$emit('hide-submit-successful-modal')
        },
        showSharePopup() {
            this.$emit('showSharePopup')
        }
    }
})

</script>

<style scoped>
    .close-btn{
        background: #009cd2;
    }
</style>
